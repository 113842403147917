
import { Watch, Prop, Mixins, Component, Ref, InjectReactive } from "vue-property-decorator";

import ETable from "@/components/ETable.vue";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { userMemberDetectionList } from "@/api/userDetection";
import dayjs from "dayjs";

import clipboard from "clipboard";

const curDomain = `${window.location.origin}`;

@Component({
    components: { ETable }
})
export default class UserDetectionRecord extends Mixins(tablePagination, loading, Resize) {
    [x: string]: any;


    queryForm = {
        source: "",
        diagnosesType: "",
        times: [],
        storeName: "",
        nickName: "",
        phone: "",
        childrenDetectionTag: "",
    };

    sourceOptions = [
        { label: "门店", value: 1 },
        { label: "小程序", value: 2 },
        { label: "养身帮连锁门店", value: 3 },
    ]

    diagnosesTypeOptions = [
        { label: "舌诊", value: "10001" },
        { label: "脉诊", value: "10003" },
        { label: "体质辨识", value: "10004" },
        { label: "肺功能检测", value: "10005" },
        { label: "健康测评", value: "10006" },
        { label: "智能体脂", value: "10008" },
    ]

    tagOptions = [
        { label: "儿童", value: "1" },
    ]

    itemTotal = 0;
    tableData = [];

    columns = [
        { label: "ID", prop: "id" },
        { label: "数据来源", prop: "sourceDesc" },
        { label: "检测类型", prop: "diagnosesType" },
        { label: "检测时间", prop: "detectionTime" },
        { label: "检测结果", prop: "scoreDesc" },
        { label: "用户名称", prop: "nickName" },
        { label: "手机号", prop: "phone" },
        { label: "设备编号", prop: "deviceCodeDesc" },
        { label: "操作", prop: "action" },
    ];

    mounted() {
        this.windowResize(310)
        this.getData(); 
    }

    handleSearch() {
        this.resetCurrent();
        this.getData();
    }

    getData() {
        this.showLoading();
        const { times } = this.queryForm;
        const params = {
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            startTime: (times || [])[0] && dayjs((times || [])[0]).format("YYYY-MM-DD HH:mm:ss"),
            endTime: (times || [])[1] && dayjs((times || [])[1]).format("YYYY-MM-DD HH:mm:ss"),
        }
        userMemberDetectionList(params).then(res => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        })
    }

    goUserList(row) {
        this.$router.push({
            path: "/userMember",
            query: { phone: row.phone }
        })
    }

    getReportUrl(row) {
        if (row.diagnosesType == 10001) { // 舌诊
            return `${curDomain}/h5/#/result?id=${row.diagnosesId}&checkId=${row.diagnosesId}&libCode=10001`;
        }
        if (row.diagnosesType == 10003) { // 脉诊
            return row.otherParam1;
        }
        if (row.diagnosesType == 10004) { // 体质辨识
            return `${curDomain}/h5/#/physique/result?id=${row.diagnosesId}&rId=${row.otherParam1}&checkId=${row.diagnosesId}&libCode=10004`;
        }
        if (row.diagnosesType == 10005) { // 肺功能评估
            return `${curDomain}/h5/#/lung/result?id=${row.diagnosesId}&rId=${row.otherParam1}&checkId=${row.otherParam1}&libCode=10005`;
        }
        if (row.diagnosesType == 10006) { // 健康测评
            return `${curDomain}/h5/#/healthExamine/result?rId=${row.diagnosesId}&libCode=10006`;
        }
        if (row.diagnosesType == 10008) { // 智能体脂
            return `${curDomain}/h5/#/electronicWeight/result?id=${row.diagnosesId}&libCode=10008`;
        }
        return "";
    }

    copy(row) {
        const url = this.getReportUrl(row);
        window.open(url);
    }

}
